<script>



import {getTaxApi} from '@/api/accounting/tax'
import moment from 'moment'
import Choices from "choices.js";

export default {

    emits: ['confirm', 'cancel'],

    props: {
        landlord : {
            type : Object,
            default: ()=> {

            }
        }
    },
    components: {
        
    },

    validations: {
        landlord : {
            
        }
    },
 

    data() {
      
        return {
            submitted : false,
            tax_year : '',
        }
       
    },
    methods: {
        formSubmit() {
            this.$v.$touch()
            console.log(this.tax_year)
            let data = {
                owner_id : this.landlord.id,
                tax_year : this.tax_year,
                start_date : '-',
                end_date   : '-'
            }
            if (this.$v.$invalid == false) {
                getTaxApi().nr4_slip_preview(data).then(res => {
                    if (res.errCode == 0) {
                        window.open(res.url, '_blank')
                    } else {
                        this.$alertify.error("NR4 Slip Failed:" + res.errCode);
                    }
                })
            }
            
        },
    },

    created() {
       
    },

    mounted() {
        let choice_array = []
        let today = moment()
        this.tax_year = today.format("YYYY")
       

        for (let i = 1; i < 5; i++) {
            today.add(-1, 'Y')
            choice_array.push({
                label: today.format("YYYY"),
                value: today.format("YYYY"),
                selected : false,
            })
        }
     
        choice_array.push({
            label: this.tax_year,
            value: this.tax_year,
            selected : true
        })
       

        new Choices('#tax_year_choice', {
            choices: choice_array
        })

    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom02">Owner Name</label>
                            <input class="form-control" v-model="landlord.name" readonly/>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="mb-3">
                            <label class="form-label" for="gen-info-name-input">Tax Year</label>
                            <select id="tax_year_choice" class="form-control" v-model="tax_year"></select>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Close</b-button>
                            <b-button  variant="primary" type="submit">Preview</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>