<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Nr4SlipQueryDialog from './components/nr4_slip_query_dialog.vue'
import {getTaxApi} from '@/api/accounting/tax'
import { financial } from '@/api/misc'

import moment from 'moment'

/**
 * User list component
 */
export default {
  page: {
    title: "NR List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      landlordList: [],
      title: "NR List",
      items: [
        {
          text: "NR",
          href: "/",
        },
        {
          text: "List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 30,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "C",
          label : '',
          sortable: true,
        },
        {
          key: "Code",
          sortable: true,
        },
        {
          key: "Name",
          sortable: true,
        },

        {
          key: "Income",
          sortable: true,
        },

        {
          key: "Tax",
          sortable: true,
        },
      
        {
          key: "Phone",
          sortable: true,
        },
        {
          key: "Email1",
          sortable: true,
        },
      
        {
          key: "Action",
          sortable: true,
        },
      ],

      current_landlord : {},
      nr_list : []
    };
  },
  components: {
    Layout,
    PageHeader,
    Nr4SlipQueryDialog
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },
 
  created() {
    this.queryList(this.currentPage)
  },
  mounted() {
  
  },
  methods: {

    f(v, d = 2) {
      return financial(v,d)
    },

    queryList(p) {
      getTaxApi().nr_list({page : {page : p, page_size: this.perPage}}).then((res)=>{
        this.landlordList = []
        res.data.map((o) =>{
        
          this.landlordList.push({
            id: o.id,
            landlord_id : o.code,
            name : o.is_corporatuin == 1? `${o.company_name}` : `${o.landlord_name}`,
            apt : o.apt,
            address :o.address,
            city_name : o.city_name,
            phone : o.phone1,
            email : o.email,
            is_resident :o.is_resident,
            checked : false,
            income : o.income,
            tax_withheld : o.tax_withheld
          })
          this.totalRows = res.page.total;
         
        }) 

      })
    },


    submit_to_cra() {
      let data = {
        tax_year   :  moment().format("YYYY"),
        nr_list    : this.nr_list,
        start_date : '-',
        end_date   : '-'
      }
      getTaxApi().submit_nr4(data).then(res => {
        if (res.errCode == 0) {
          window.open(res.url, '_blank')
        }
      })
    },

    pageChange(p) {
      this.queryList(p)
    },

   
    showNr4Slip(owner) {
      this.current_landlord = owner
      this.$bvModal.show('nr4_slip_query_dialog')
     
    },

    onNRSelected(evt, nr) {

      let owner =  this.landlordList.find(e => e.id == nr.id)
      if (evt.target.checked == true) {
        let cache = this.nr_list.find(e => e == nr.id)
        if (!cache) {
          this.nr_list.push(nr.id)
        }
      } else {
        this.nr_list = this.nr_list.filter(e => e != nr.id)
      }

      owner.checked = evt.target.checked
      
    },

    onNRSelectedAll(evt) {
      this.nr_list = []
   
      this.landlordList.map(e => {
        if (evt.target.checked == true) {
          this.nr_list.push(e.id);
        }

        e.checked = evt.target.checked
      })
   
    },


  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <h5 class="card-title">
                    NR List
                    <span class="text-muted fw-normal ms-2">({{ this.totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >

                  <b-dropdown
                    variant="white"
                    right
                    toggle-class="btn-link text-dark shadow-none"
                  >
                    <template v-slot:button-content>
                      <i class="uil uil-ellipsis-h"></i>
                    </template>
                    <b-dropdown-item @click="submit_to_cra">Submit and Download</b-dropdown-item>
                  </b-dropdown>
                  <!-- end dropdown -->

                  
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->


            <b-modal centerd id="nr4_slip_query_dialog" title="Owner Nr4 Query" size="lg" hide-footer>
              <Nr4SlipQueryDialog :landlord="current_landlord"  @cancel="$bvModal.hide('nr4_slip_query_dialog')"/>
            </b-modal>

            

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="landlordList"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  class="table-check"
                >

                  <template  #head(C)>
                    <input  type="checkbox"  class="form-check-input"  id="checkAll" @change="onNRSelectedAll($event)"  />
                  </template>
                  <template  #cell(C)="data">
                    <div class="form-check font-size-16">
                      <input type="checkbox" class="form-check-input"  @change="onNRSelected($event, data.item)" :checked="data.item.checked"/>
                    </div>
                  </template>

                  <template  #cell(Code)="data">
                    <b-link @click="showLandlordInfo(data.item)" >{{ data.item.landlord_id}}</b-link>
                  </template>
                  <template #cell(Name)="data">
                    {{ data.item.name }}
                  </template>

                  <template #cell(Income)="data">
                    ${{ f(data.item.income).toLocaleString() }}
                  </template>

                  <template #cell(Tax)="data">
                    ${{ f(data.item.tax_withheld).toLocaleString() }}
                  </template>
                 
                  <template #cell(Phone)="data">
                    {{ data.item.phone }}
                  </template>
                  <template #cell(Email1)="data">
                    {{ data.item.email }}
                  </template>
                  
                  <template #cell(action)="data">
                    <b-dropdown variant="light" right size="sm">
                      <template v-slot:button-content>
                        <i class="uil uil-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item @click="showNr4Slip(data.item)" :disabled="data.item.is_resident == 1">Nr4Slip</b-dropdown-item>
                    </b-dropdown>
                  </template>


                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        @change="pageChange"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
